import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import clsx from 'clsx';

import React, { useEffect, useState } from 'react';
import { getLocalData } from 'src/utils';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const CustomContractTable = ({
  data,
  label,
  filterCompoenent,

  setContactId,
  setPricingDialog,
  resumeContract,
  setnextBilling,
  setContractItemId,
  setErrorMessage,
  setShowErrorMessage,
  cancelContract,
  setItemType,
  openDetail
}) => {
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [keys, setKeys] = useState([]);
  const [displayFilterForm, setdisplayFilterForm] = useState(false);
  const [selectedItem, setselectedItem] = useState(null);
  const [contractDialog, setContractDialog] = useState(false);
  const [Isduplicate, setIsduplicate] = useState(false);
  const [type, setType] = useState('');
  const isFranchisor = getLocalData('isFranchisor');

  const TodayDate = new Date();

  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'scale(-1)',
      color: '#FFFFFF'
    },
    closeY: {
      transform: 'scale(1)',
      color: '#003087'
    }
  }));
  const classes = useStyles();

  // Handle search text change
  const handleSearchTextChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    setPage(0); // Reset page when search text changes
  };

  // Filter data based on search text
  const filteredData = data.filter((item) => {
    return Object?.values(item)?.some((value) =>
      value?.toString()?.toLowerCase()?.includes(searchText)
    );
  });

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page when rows per page changes
  };

  const paginatedData = filteredData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  function removeDuplicates(data) {
    const result = {};

    data?.forEach((item) => {
      const { oldOrderId, itemId, totalNumberOfEMIPaid } = item;
      const key = `${oldOrderId}_${itemId}`;
      if (
        !result[key] ||
        result[key]?.totalNumberOfEMIPaid < totalNumberOfEMIPaid
      ) {
        result[key] = item;
      }
    });

    return Object.values(result);
  }

  const uniqueData = removeDuplicates(paginatedData || []);

  // Show filter
  const showFilter = () => {
    setdisplayFilterForm(!displayFilterForm);
  };

  // Get filter size
  const getFilterSize = () => {
    return displayFilterForm ? 300 : 0;
  };

  // Hanle child pause click
  const childPauseCLick = () => {
    setAnchorEl(null);
    setPricingDialog(true);
  };

  // Methdo to handle resume
  const handleResume = (e, sortKey, status, itemId, todate, selectedItem) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      todate &&
      (new Date(todate) > new Date() ||
        new Date(todate).getDate() === new Date().getDate())
    ) {
      setAnchorEl(null);
      setErrorMessage(
        'You can not resume the contract before its pause period has reached its end date.'
      );
      setShowErrorMessage(true);
      // return;
    } else {
      setType('resume');
      setAnchorEl(null);
      setIsduplicate(false);
      setContractDialog(true);
      setContactId(sortKey);
      setContractItemId(itemId);
      setItemType(selectedItem);
    }
  };

  const handleCancel = () => {
    setType('cancel');
    setAnchorEl(null);
    setContractDialog(true);
  };

  useEffect(() => {
    let key = data && data.length > 0 && data[0] ? Object.keys(data[0]) : '';
    if (key) setKeys(key);
  }, [data]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Method to handle the popover open
  const handlePopoverOpen = (event, rest) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setselectedItem(rest);
  };
  // Method to handle the popover close
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Methdo to handle the delete dialog
  const handleContractDialog = (type) => {
    if (type === 'close') {
      setContractDialog(false);
      setIsduplicate(false);
    } else if (type === 'resume') {
      setContractDialog(false);
      // check if duplicate or resume
      if (Isduplicate) {
        setIsduplicate(false);
      } else {
        // resume contract
        resumeContract();
        setIsduplicate(false);
      }
    } else if (type === 'cancel') {
      setContractDialog(false);
      cancelContract(selectedItem);
      setIsduplicate(false);
    }
  };

  console.log(uniqueData, 'uniqueDatauniqueDatauniqueData');

  return (
    <>
      <Box>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid
              item
              sx={{
                marginTop: '10px',
                marginBottom: '20px'
              }}
            >
              {filterCompoenent && (
                <Button
                  onClick={showFilter}
                  variant={displayFilterForm ? 'contained' : 'outlined'}
                >
                  Filters
                  <KeyboardArrowDownRoundedIcon
                    className={clsx(
                      !displayFilterForm && classes.closeY,
                      displayFilterForm && classes.openY
                    )}
                  />
                </Button>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '10px',
                marginBottom: '20px'
              }}
              className="top-filter-cont"
            >
              <TextField
                type="search"
                className="Search-field"
                label="Search"
                InputProps={{
                  style: {},
                  endAdornment: <SearchIcon />
                }}
                onChange={handleSearchTextChange}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* END */}

        <Grid container spacing={2}>
          {displayFilterForm === true ? (
            <Grid item width={getFilterSize()}>
              {filterCompoenent}
            </Grid>
          ) : null}
          <Grid item sx={{ width: `calc(100% - ${getFilterSize()}px)` }}>
            <Card sx={{ padding: '15px', borderRadius: '19px 19px 0px 0px' }}>
              <CardHeader
                title={label}
                className="table-header-name"
                sx={{ fontSize: '20px', color: '#003087', padding: '5px 16px' }}
              />
              <TableContainer className="Custom-Table table-container table-header-capitalization">
                <Table>
                  <TableHead>
                    <TableRow key={0}>
                      {keys.map((item, indexhead) =>
                        indexhead > 2
                          ? item !== 'subscriptionStatus' &&
                            item !== 'nextDateOfBilling' &&
                            item !== 'oldOrderId' &&
                            item !== 'totalNumberOfEMIPaid' &&
                            item !== 'locationId' &&
                            item !== 'orderId' &&
                            item !== 'dateOfExpiry' &&
                            item !== 'contractMembershipName' &&
                            item !== 'refundStatus' &&
                            item !== 'pausedBy' && (
                              <TableCell
                                key={indexhead}
                                className={
                                  indexhead < 2
                                    ? 'h-partitionKey h-sortKey'
                                    : ''
                                }
                              >
                                {item}
                              </TableCell>
                            )
                          : ''
                      )}
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData?.length > 0 ? (
                      paginatedData.map((item, indexpage) => {
                        let totalNumberOfEMIPaid =
                          uniqueData?.some(
                            (data) => item?.sortKey === data.sortKey
                          ) || false;

                        return (
                          <>
                            <TableRow
                              key={indexpage}
                              className="table-row cursor-pointe txt-Transform"
                              onClick={(e) => openDetail(item, 3, e)}
                            >
                              {keys.map((key, indexkey) => {
                                return indexkey > 2 ? (
                                  key === 'Pause Contract' &&
                                  key !== 'nextDateOfBilling' &&
                                  key !== 'oldOrderId' &&
                                  key !== 'locationId' &&
                                  key !== 'orderId' &&
                                  key !== 'totalNumberOfEMIPaid' &&
                                  key !== 'contractMembershipName' &&
                                  key !== 'dateOfExpiry' &&
                                  key !== 'refundStatus' ? (
                                    <TableCell key={indexkey}>
                                      {item[key]}
                                    </TableCell>
                                  ) : (
                                    key !== 'subscriptionStatus' &&
                                    key !== 'nextDateOfBilling' &&
                                    key !== 'oldOrderId' &&
                                    key !== 'locationId' &&
                                    key !== 'orderId' &&
                                    key !== 'contractMembershipName' &&
                                    key !== 'totalNumberOfEMIPaid' &&
                                    key !== 'dateOfExpiry' &&
                                    key !== 'refundStatus' &&
                                    key !== 'pausedBy' && (
                                      <TableCell key={indexkey}>
                                        {item[key]}
                                      </TableCell>
                                    )
                                  )
                                ) : (
                                  ''
                                );
                              })}
                              <TableCell
                                className="table-td"
                                style={{
                                  visibility:
                                    item.status === 'Complete' ||
                                    item.status === 'canceled' ||
                                    !totalNumberOfEMIPaid
                                      ? 'hidden'
                                      : 'visible'
                                }}
                              >
                                <IconButton
                                  onClick={(e) => handlePopoverOpen(e, item)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={keys.length}>
                          No data found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                keepMounted
                PaperProps={{
                  style: {
                    maxHeight: 40 * 5,
                    width: '13ch'
                  }
                }}
              >
                {selectedItem?.status === 'Paused' &&
                  ((selectedItem?.Duration !== 'Ongoing' &&
                    selectedItem?.dateOfExpiry) ||
                    selectedItem?.Duration === 'Ongoing') && (
                    <MenuItem
                      disabled={selectedItem?.refundStatus === 'COMPLETED'}
                      onClick={(e) => {
                        handleResume(
                          e,
                          selectedItem['sortKey'],
                          selectedItem['subscriptionStatus'],
                          selectedItem['itemId'],
                          selectedItem['To'],
                          selectedItem['Type']
                        );
                      }}
                    >
                      Resume
                    </MenuItem>
                  )}
                {selectedItem?.status === 'Active' &&
                  ((selectedItem?.Duration !== 'Ongoing' &&
                    selectedItem?.dateOfExpiry) ||
                    selectedItem?.Duration === 'Ongoing') && (
                    <MenuItem
                      disabled={
                        (selectedItem?.dateOfExpiry &&
                          new Date(selectedItem?.dateOfExpiry) <= TodayDate) ||
                        selectedItem?.refundStatus === 'COMPLETED'
                      }
                      onClick={(e) => {
                        childPauseCLick(e);
                        setnextBilling(selectedItem['nextDateOfBilling']);
                        setContactId(selectedItem['sortKey']);
                        setContractItemId(selectedItem['itemId']);
                        setItemType(selectedItem['Type']);
                      }}
                    >
                      Pause
                    </MenuItem>
                  )}

                {isFranchisor === 'false' && (
                  <MenuItem onClick={handleCancel}>Cancel</MenuItem>
                )}
              </Menu>

              {/* Pagination */}
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={contractDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleContractDialog('close')}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="alert-title align-center txt-Transform">
          {Isduplicate ? 'Duplicate Contract' : `${type} ${selectedItem?.Type}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="alert-decription align-center"
          >
            <Box display="flex" justifyContent="center" direction="column">
              <Typography className="title-text-sub">
                {Isduplicate
                  ? 'Do you want to create duplicate contract?'
                  : `Are you sure, you want to ${type} ${
                      selectedItem?.Type === 'Membership'
                        ? 'membership'
                        : 'contract'
                    }?`}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions className="alert-button-control">
          <Button
            className="font-17 no-hover"
            onClick={() => handleContractDialog(type)}
          >
            Yes
          </Button>
          <Divider className="vertical-divider" />
          <Button
            className="font-17 cancel-btn no-hover"
            onClick={() => handleContractDialog('close')}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};