/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  Card,
  Grid,
  Paper,
  Box,
  Autocomplete,
  TextField
} from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import { CustomDatePicker } from 'src/components/CustomDatePicker';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';

export const UpcomingScheduleFilters = ({
  setFilters,
  filters,
  allPets,
  serviceCategory
}) => {
  const useStyles = makeStyles(() => ({
    openY: {
      transform: 'rotate(90deg)',
      color: '#003087'
    },
    closeY: {
      transform: 'rotate(0deg)',
      color: '#003087'
    }
  }));

  // create a custom theme with updated hover and close icon colors
  const useAutocompleteStyles = makeStyles({
    inputRoot: {
      // borderRadius: 10, // or any other value you prefer
    },
    option: {
      '&:hover': {
        color: 'white'
        // or any other color you prefer
      }
    },
    clearIndicator: {
      color: '#003087', // or any other color you prefer
      backgroundColor: '#F8FBFF',
      '&:hover': {
        backgroundColor: '#F8FBFF'
      }
    }
  });

  const autoCompleteClasses = useAutocompleteStyles();
  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState({
    serviceCategory: false,
    dog: false,
    datefilter: false
  });
  const [startDate, setstartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const paymentType = [
    { name: 'Cash' },
    { name: 'Gift Card' },
    { name: 'Credit Card' }
  ];

  const handleOpenFilter = (field) => {
    setOpenFilter((prevState) => ({
      serviceCategory: field === 'serviceCategory' ? !prevState.serviceCategory : false,
      dog: field === 'dog' ? !prevState.dog : false,
      datefilter: field === 'datefilter' ? !prevState.datefilter : false,
    }));
  };

  const changeServiceCategory = (selectedOpt) => {
    setFilters((prevState) => ({ ...prevState, categoryName: selectedOpt }));
  };

  const changeDog = (selectedOpt) => {
    
    let petId;
    if(selectedOpt==='All'){
      petId=''
    }else{
       petId = selectedOpt;
    }
    // let encodedPetId = petId && encodeURIComponent(petId);
    setFilters((prevState) => ({ ...prevState, petId: petId }));
  };

  const changeStartDate = (date) => {
    if (date === '01-01-1970') {
      date = null;
      return;
    }
    setstartDate(date);
    setFilters((prevState) => ({
      ...prevState,
      fromDate: date
    }));
  };

  const changeEndDate = (date) => {
    if (date === '01-01-1970') {
      date = null;
      return;
    }
    setEndDate(date);
    setFilters((prevState) => ({
      ...prevState,
      toDate: date
    }));
  };

  const clearFilters = () => {
    setFilters({
      status: filters.status,
      categoryName: null,
      petId: null,
      createdAt: null,
      fromDate: null,
      toDate: null
    });

    setOpenFilter({
      serviceCategory: false,
      dog: false,
      datefilter: false
    });
  };

  return (
    <>
      <Card className="filter-content custom-filter-fields">
        {/* <Typography className="filter-label" pt={2} pb={3}>
          Type
        </Typography>

        <FormControl
          pt={2}
          fullWidth
          variant="outlined"
          sx={{
            width: '100%',
            marginBottom: '10px'
          }}
        >
          <InputLabel> Types</InputLabel>
          <Select
            value={filters.status ?? ''}
            onChange={(event) =>
              // handleChangeFilter(event.target.value, 'isActive')
              handleChangeFilter(event.target.value, 'status')
            }
            label="Active"
            className="status-field field-label-css"
          >
            <MenuItem value={null}>All</MenuItem>
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Inactive</MenuItem>
          </Select>
        </FormControl>

        <Divider /> */}

        <Grid mt={2}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography
                className="filter-text filter-text-title"
                sx={{
                  fontWeight: '600',

                  fontSize: '16px'
                }}
              >
                Filters
              </Typography>
            </Grid>

            <Grid item onClick={clearFilters}>
              <Typography
                className="filter-text"
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Clear
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item onClick={() => handleOpenFilter('dog')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.dog && classes.closeY,
                    openFilter.dog && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Dog</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.dog && (
          <Box marginTop={1}>
            {/* <Autocomplete
              disablePortal
              value={filters.dog}
              classes={autoCompleteClasses}
              onChange={(event, newValue) => {
                changeDog(newValue);
              }}
              className="status-field field-label-css"
              // options={allPets && allPets.map((option) => option.firstName + ' ' + option.lastName)}
              options={allPets && allPets}
              getOptionLabel={(option) =>( option.firstName + " " + option.lastName)}
              sx={{
                width: '100%'
              }}
              renderInput={(params) => (
                <TextField {...params} label={params?.label || 'Dog'} />
              )}
            /> */}
            <FormControl fullWidth variant="outlined">
              <InputLabel>Dog</InputLabel>
              <Select
                onChange={(event) =>
                  changeDog(event.target.value)
                }
                value={filters.dog}
                sx={{
                  width: '100%'
                }}
                label="Select Dog"
                className="status-field field-label-css"
              >
                <MenuItem value={'All'}>All</MenuItem>
                {allPets && allPets.map((option) => (
                  <MenuItem value={option.sortKey}>{option.firstName + " " + option.lastName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        <Grid item onClick={() => handleOpenFilter('serviceCategory')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.serviceCategory && classes.closeY,
                    openFilter.serviceCategory && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Service Category</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.serviceCategory && (
          <Box marginTop={1}>
            <Autocomplete
              // disablePortal
              value={filters.categoryName}
              classes={autoCompleteClasses}
              onChange={(event, newValue) => {
                changeServiceCategory(newValue);
              }}
              className="status-field field-label-css"
              options={serviceCategory && serviceCategory.map((option) => option.name)}
              sx={{
                width: '100%'
              }}
              renderInput={(params) => (
                <TextField {...params} label={params?.label || 'Category'} />
              )}
            />
          </Box>
        )}

        <Grid xs={12} onClick={() => handleOpenFilter('datefilter')} pt={0.5}>
          <Paper>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              className="pointer"
            >
              <Grid item>
                <KeyboardArrowRightRoundedIcon
                  className={clsx(
                    !openFilter.datefilter && classes.closeY,
                    openFilter.datefilter && classes.openY
                  )}
                />
              </Grid>
              <Grid item>
                <Typography>Date</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {openFilter.datefilter && (
          <>
            <Box marginTop={1}>
              <CustomDatePicker label="Start Date" onChange={changeStartDate} />
            </Box>
            <Box marginTop={1}>
              <CustomDatePicker
                label="End Date"
                onChange={changeEndDate}
                minDate={new Date(startDate)}
                disabled={Boolean(!startDate)}
              />
            </Box>
          </>
        )}

      </Card>
    </>
  );
};