import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { ApiService } from 'src/services/ApiService';
import Header from 'src/components/Header';
import { getLocalData } from 'src/utils';
import { Helmet } from 'react-helmet-async';
import GlobalData from 'src/global';

const ContractsReport = () => {
  const [embedUrl, setEmbedUrl] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [reportId, setReportId] = useState('');
  const locationId = getLocalData('locationId');
  const [isError, setIsError] = useState(false);
  const title = GlobalData[0].title;
  const isFranchisor = getLocalData('isFranchisor');

  const franchisorReportId = '3f4ec313-b274-4cab-84f8-5423218fd70f';
  const franchiseeReportId = 'd12cf863-c51b-4c23-9e3b-912dcc2fcd62';

  const getPowerBiDetails = async () => {
    try {
      const selectedReportId = isFranchisor === 'true' ? franchisorReportId : franchiseeReportId;

      const response = await ApiService.get(
        `${process.env.REACT_APP_MEMBERSHIP_API_END_POINT}reports/getEmbedReport?reportId=${selectedReportId}&workspaceId=adf52a44-8799-4708-b9ef-181ec598450a&editable=Y`
      );

      let final = response?.embedUrl?.[0]?.embedUrl ?? '';

      // Add filter only if isFranchisor is false
      if (isFranchisor === 'false') {
        final += `&filter=Franchisee/locationId eq '${locationId}'`;
      }

      console.log(final, 'finalfinalfinalfinalfinalfinal');

      setEmbedUrl(final);
      setIsError(false);
      setAccessToken(response?.accessToken ?? '');
      setReportId(response?.embedUrl?.[0]?.reportId ?? '');
    } catch (error) {
      console.error('Error fetching Power BI details:', error);
      setIsError(true);
    }
  };

  useEffect(() => {
    getPowerBiDetails();
  }, []);

  return (
    <>
      <Helmet>
        <title>Contract Report | {title}</title>
      </Helmet>
      <div className="container align-center" style={{ height: '100vh' }}>
        <Container
          maxWidth="100%"
          className="main-container"
          style={{ height: '100%' }}
        >
          <Header title="" />
          {!isError ? (
            <div className="powerbi-container" style={{ height: '100%' }}>
              <PowerBIEmbed
                embedConfig={{
                  type: 'report',
                  id: reportId,
                  embedUrl: embedUrl,
                  accessToken: accessToken,
                  tokenType: models.TokenType.Embed
                  // settings: {
                  //   panes: {
                  //     filters: {
                  //       expanded: true,
                  //       visible: true
                  //     }
                  //   }
                  // }
                  // filters: [
                  //   {
                  //     $schema: 'http://powerbi.com/product/schema#basic',
                  //     target: {
                  //       table: 'Order Location',
                  //       column: 'locationId'
                  //     },
                  //     operator: 'In',
                  //     Values: [locationId],
                  //     filterType: models.FilterType.Basic,
                  //     requireSingleSelection: true
                  //   }
                  // ]
                }}
                eventHandlers={
                  new Map([
                    [
                      'loaded',
                      function () {
                        console.log('Report loaded');
                      }
                    ],
                    [
                      'rendered',
                      function () {
                        console.log('Report rendered');
                      }
                    ],
                    [
                      'error',
                      function (event) {
                        console.log(event.detail);
                      }
                    ]
                  ])
                }
                cssClassName={'embed-container'}
                getEmbeddedComponent={(embeddedReport) => {
                  window.report = embeddedReport;
                }}
              />
            </div>
          ) : (
            <h1>Report is not available.</h1>
          )}
        </Container>
      </div>
    </>
  );
};

export default ContractsReport;