import {
  Button,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide
} from '@mui/material';
import DrawLocationTable from './DrawLocationTable';
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef
} from 'react';
import Loader from '../../../components/Loader';
// import { toast } from 'react-toastify';
import { getLocalData } from 'src/utils';
import ErrorMessage from '../../../components/PopupMessage/ErrorMessage';
import { ApiService } from 'src/services/ApiService';
// import SuccessMessage from 'src/components/PopupMessage/SuccessMessage';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const LocationListAPI = forwardRef(
  (
    { setShowScreen, showScreen, franchiseeList, managerList, statesList },
    ref
  ) => {
    // const domain = Environment[0].apiEndPoint;
    const [locationList, setLocation] = useState([]);
    const [dataCount, setdataCount] = useState(0);
    // show loader when login button is clicked
    const [isLoading, setIsLoading] = useState(false);
    const [ispopup, setIsPopup] = useState(false);
    const userId = getLocalData('userId');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    // const [franchiseeId, setFranchiseeId] = useState(false);
    let [filters, setSidebarFilters] = useState({
      // isActive: null,
      status: 1,
      state: null,
      managerId: null,
      city: '',
      openingStartDate: null,
      openingEndDate: null
    });

    // const franchiseeId = getLocalData('franchiseeId');

    const [selectedLocationLists, setSelectedLocationLists] = useState([]);
    const [open, setOpen] = React.useState(false);
    //  const [showSucessMessage, setShowSuccessMessage] = useState(false);
    // const [sucessMessage, setSuccessMessage] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // check updated or saved
    const [isUpdated, setIsUpdated] = useState(false);

    // Get locations method to get the list of location
    const getLocations = async () => {
      // Now show the loader
      if (!ispopup) setIsLoading(true);
      let filtersParams = JSON.parse(JSON.stringify(filters));
      Object.keys(filtersParams).forEach((key) => {
        if (
          filtersParams[key] === null ||
          filtersParams[key] === -1 ||
          filtersParams[key] === '' ||
          filtersParams[key] === 'undefined' ||
          filtersParams[key] === undefined
        ) {
          delete filtersParams[key];
        }
      });

      let queryString = Object.keys(filtersParams)
        ?.map((key) => key + '=' + filtersParams[key])
        ?.join('&');

      try {
        const data = await ApiService.get(
          `${process.env.REACT_APP_API_END_POINT}locations?${queryString}`
        );

        if (data?.statusCode && !data?.error) {
          setLocation(data?.data?.Items);
          setdataCount(data?.data?.TotalCount);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    // setFranchiseeId(locationList.partitionKey);
    // gets call the method to get the data

    useEffect(() => {
      // Not call the get location method
      getLocations();
    }, [JSON.stringify(filters)]);

    // Getting hooks from parent to child
    useImperativeHandle(ref, () => ({
      refreshLocationData: () => {
        getLocations();
      }
    }));

    // Handle the event from child to parent. It is being used for refresh the data after edit or delete
    const refreshLocationData = () => {
      setIsPopup(true);

      getLocations();
    };

    const handleBulkAdd = async () => {
      setIsUpdated(false);
      setOpen(true);
    };

    const confirmBulkUpdate = async () => {
      setOpen(false);
      setBtnLoading(true);
      setIsLoading(true);
      const data = {
        status: selectedStatus,
        updatedBy: userId,
        locationIds: selectedLocationLists
      };

      try {
        const response = await ApiService.post(
          `${process.env.REACT_APP_FEATURED_API_END_POINT}locations/updateBulk`,
          data
        );

        if (!response?.error) {
          setSelectedStatus('');
          setSelectedLocationLists([]);
          setIsUpdated(true);
          setOpen(true);
        } else {
          setShowErrorMessage(true);
          setErrorMessage(
            response?.message ||
              'Something went wrong with update location status'
          );
        }

        setIsLoading(false);
        setBtnLoading(false);
      } catch (error) {
        console.error(error);
        setBtnLoading(false);
        setIsLoading(false);
        setShowErrorMessage(true);
        setErrorMessage('Something went wrong, Try again!');
      }
    };

    const handleClose = (val) => {
      if (val === 'Save') {
        setOpen(false);
        confirmBulkUpdate();
      } else if (val === 'Discard') {
        setOpen(false);
        if (isUpdated) {
          getLocations();
          setIsUpdated(false);
        }
      }
    };

    return (
      <Grid className="card-table-main" xs={12}>
        {showErrorMessage && (
          <ErrorMessage
            message={errorMessage}
            setShowMessage={setShowErrorMessage}
          />
        )}

        <Loader IsLoading={isLoading} />

        <DrawLocationTable
          locationLists={locationList}
          sideBarfilters={filters}
          setSidebarFilters={setSidebarFilters}
          refreshLocationData={refreshLocationData}
          dataCount={dataCount}
          setShowScreen={setShowScreen}
          showScreen={showScreen}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          handleBulkAdd={handleBulkAdd}
          btnLoading={btnLoading}
          selectedLocationLists={selectedLocationLists}
          setSelectedLocationLists={setSelectedLocationLists}
          open={open}
          setOpen={setOpen}
          franchiseeList={franchiseeList}
          managerList={managerList}
          statesList={statesList}
        />

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleClose('Cancel')}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="alert-title align-center">
            {isUpdated ? '' : 'Activate/Deactivate Selected Items'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="alert-decription align-center"
            >
              <Box display="flex" justifyContent="center" direction="column">
                {!isUpdated ? (
                  <Typography className="title-text-sub">
                    Are you sure you want to mark Activate/Deactivate,
                    <Typography className="title-text-sub">
                      all the selected items?
                    </Typography>
                  </Typography>
                ) : (
                  <Typography className="title-text-sub">
                    All the selected locations have been updated successfully!
                  </Typography>
                )}
              </Box>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions className="alert-button-control">
            <Button
              className="font-17 text-black no-hover"
              onClick={() => handleClose('Discard')}
            >
              {isUpdated ? 'Close' : 'Cancel'}
            </Button>
            {!isUpdated && <Divider className="vertical-divider" />}
            {!isUpdated && (
              <Button
                className="font-17 no-hover"
                onClick={() => handleClose('Save')}
              >
                confirm
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
);

export default LocationListAPI;
