import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { alpha, Box, List, styled, Button, ListItem } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { Link } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Menus } from './Navigations';
import { UserContext } from '../../../../contexts/UserContext';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar, sidebarToggleHalf, sidebarToggle, toggleSidebarHalf } =
    useContext(SidebarContext);
  // const { setShowScreen } = useContext(HeaderButtonContext);

  let half = '';
  let menuonly = '';
  const [hovered, setHovered] = useState({
    Reports: false,
    Inventory: false,
    POS: false,
    'Business Operations': false,
    Franchises: false,
    Settings: false,
    Marketing: false,
    Administrative: false,
    Scheduling: false,
    'Franchise Operations': false
  });
  const location = useLocation();
  const { userData } = useContext(UserContext);

  // window width
  if (window.innerWidth < 1280) {
    half = '';
    // toggleSidebarHalf(true);
    // IsSmall = false;
  } else {
    half = sidebarToggleHalf ? '' : 'list-link-container';
    menuonly = sidebarToggleHalf ? '' : 'only-navigation-icon';
    // IsSmall = sidebarToggleHalf ? !closeSidebar : false;
  }

  const showDropDown = (navName, value) => {
    let values = false;
    if (!value) values = true;
    setHovered((...prev) => !prev[navName]);
    if (navName === 'Reports') {
      setHovered((prevState) => ({ ...prevState, Reports: values }));
    } else if (navName === 'Inventory') {
      setHovered((prevState) => ({ ...prevState, Inventory: values }));
    } else if (navName === 'POS') {
      setHovered((prevState) => ({ ...prevState, POS: values }));
    } else if (navName === 'Business Operations') {
      setHovered((prevState) => ({
        ...prevState,
        'Business Operations': values
      }));
    } else if (navName === 'Franchises') {
      setHovered((prevState) => ({ ...prevState, Franchises: values }));
    } else if (navName === 'Marketing') {
      setHovered((prevState) => ({ ...prevState, Marketing: values }));
    } else if (navName === 'Administrative') {
      setHovered((prevState) => ({ ...prevState, Administrative: values }));
    } else if (navName === 'Settings') {
      setHovered((prevState) => ({ ...prevState, Settings: values }));
    } else if (navName === 'Scheduling') {
      setHovered((prevState) => ({ ...prevState, Scheduling: values }));
    } else if (navName === 'Franchise Operations') {
      setHovered((prevState) => ({
        ...prevState,
        'Franchise Operations': values
      }));
    }
  };

  const resetAndClose = (isChild) => {
    if (!isChild) {
      setHovered({
        Reports: false,
        Inventory: false,
        POS: false,
        'Business Operations': false,
        Franchises: false,
        Settings: false,
        Marketing: false,
        Administrative: false,
        Scheduling: false,
        'Franchise Operations': false
      });
    }
    closeSidebar();
  };

  useEffect(() => {
    setHovered({
      Reports: false,
      Inventory: false,
      POS: false,
      'Business Operations': false,
      Franchises: false,
      Settings: false,
      Marketing: false,
      Administrative: false,
      Scheduling: false,
      'Franchise Operations': false
    });
  }, [sidebarToggleHalf, sidebarToggle]);

  return (
    <>
      <MenuWrapper>
        <List
          component="div"
          className={sidebarToggleHalf ? '' : 'list-link-container'}
        >
          <SubMenuWrapper>
            <List
              component="div"
              className={`menu-list ${sidebarToggle ? '' : half}`}
            >
              {Menus.map((item, index) => {
                let childrens =
                  item && item.childrens && item.childrens.length > 0
                    ? item.childrens
                    : [];
                let userRole =
                  userData &&
                  userData.isFranchisor &&
                  userData.isFranchisor === 'true' &&
                  userData.userRole === 'Manager'
                    ? `Super ${userData.userRole}`
                    : userData.userRole;

                return (
                  <React.Fragment key={index}>
                    {childrens.length > 0 &&
                    childrens.some((childItem) =>
                      childItem.roles.includes(userRole)
                    ) ? (
                      <>
                        <ListItem
                          component="div"
                          className="list-item-link"
                          title={item.title}
                          key={index} // Add key prop here for each ListItem
                        >
                          <Button
                            disableRipple
                            onClick={() => {
                              if (!sidebarToggleHalf) {
                                toggleSidebarHalf();
                              }
                              setTimeout(() => {
                                showDropDown(item.title, hovered[item.title]);
                              }, 300);
                            }}
                            to={childrens.map((child) => child.path).join(',')}
                            startIcon={item.icon}
                            className={`list-btn-link ${
                              sidebarToggleHalf ? '' : menuonly
                            } ${
                              childrens.find(
                                (obj) => obj.path === location.pathname
                              ) || hovered[item.title]
                                ? 'active'
                                : ''
                            }`}
                          >
                            {(sidebarToggleHalf || sidebarToggle) && (
                              <span>{item.title}</span>
                            )}
                            {(sidebarToggleHalf || sidebarToggle) &&
                              (hovered[item.title] ? (
                                <ExpandLessIcon className="icon-style ml subMenu-Icon" />
                              ) : (
                                <ExpandMoreIcon className="subMenu-Icon ml" />
                              ))}
                          </Button>
                          {hovered[item.title] && (
                            <ul className="list-on-hover">
                              {childrens.map((childItem, childIndex) => {
                                return (
                                  <React.Fragment key={childIndex}>
                                    {' '}
                                    {/* Added unique key */}
                                    {childItem.roles.includes(userRole) && (
                                      <li
                                        key={childIndex} // Ensure unique key for each li
                                        className={`list-text-style ${
                                          childItem.path === location.pathname
                                            ? 'active'
                                            : ''
                                        }`}
                                      >
                                        <Link
                                          to={childItem.path}
                                          className="link-style"
                                          onClick={() => {
                                            if (sidebarToggleHalf) {
                                              toggleSidebarHalf();
                                            }
                                            resetAndClose(true);
                                          }}
                                        >
                                          {childItem.title}
                                        </Link>
                                      </li>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </ul>
                          )}
                        </ListItem>
                      </>
                    ) : (
                      <>
                        {item.roles.includes(userRole) && (
                          <ListItem
                            component="div"
                            className="list-item-link"
                            title={item.title}
                            key={index} // Ensure unique key for each ListItem
                          >
                            <Button
                              disableRipple
                              component={RouterLink}
                              onClick={() => resetAndClose(false)}
                              to={item.path}
                              startIcon={item.icon}
                              className={`list-btn-link ${
                                sidebarToggleHalf ? '' : menuonly
                              }`}
                            >
                              {(sidebarToggleHalf || sidebarToggle) && (
                                <span>{item.title}</span>
                              )}
                            </Button>
                          </ListItem>
                        )}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
